<template>
  <div>
    <b-row class="mt-1 mb-2">
      <b-col cols="12">
        <b-button
          variant="primary"
          class="float-left mr-1"
          @click="downloadForm"
        >
          Download Formulir Pendaftaran
        </b-button>
        <b-button variant="primary" class="float-right" @click="exportExcell">
          Download Excel
        </b-button>
      </b-col>
    </b-row>
    <b-card class="mb-5">
      <b-row>
        <b-col>
          <div class="custom-search d-flex justify-content-start">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Filter</label>
                <b-form-select
                  id="filter"
                  :options="option"
                  v-model="filter"
                  @change="onFilterChange()"
                />
                <b-form-select
                  id="subFilter"
                  class="ml-1"
                  :options="optionSub"
                  v-model="subFilter"
                  v-show="showSubFilter"
                  @change="onSubFilterChange()"
                />
              </div>
            </b-form-group>
            <b-form-group>
              <div class="d-flex align-items-center ml-2">
                <label class="mr-1">Date</label>
                <flat-pickr
                  v-model="filterDate"
                  class="form-control"
                  :config="{
                    dateFormat: 'Y-m-d',
                    minDate: '2022-03-21',
                    mode: 'range',
                  }"
                  placeholder="Date"
                  @on-change="onDateChange()"
                />
              </div>
            </b-form-group>
          </div>
        </b-col>
        <b-col>
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="search"
                  placeholder="Search"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination
            :value="meta.current_page"
            :total-rows="meta.total"
            :per-page="meta.per_page"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-2"
            @input="
              (value) =>
                getDatas(
                  value,
                  this.subFilter ? this.subFilter : this.filter,
                  this.search,
                  this.filterDate
                )
            "
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{
          enabled: false,
        }"
        :pagination-options="{
          enabled: true,
          perPage: meta.per_page,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Status -->
          <span
            class="d-block mt-50"
            v-if="props.column.field === 'created_at'"
          >
            {{ props.row.created_at }}
          </span>
          <span
            class="d-block mt-50"
            v-else-if="props.column.field === 'status'"
          >
            <b-badge :variant="statusVariant(props.row.status)">
              {{ statusName(props.row.status) }}
            </b-badge>
          </span>
          <span v-else-if="props.column.field === 'action'">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-if="props.row.status > 0"
                @click="showDetail(props.row.id)"
              >
                <feather-icon icon="EyeIcon" class="mr-50" />
                <span>Detail</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="props.row.status === 0"
                @click="
                  onContinue(
                    props.row.id,
                    props.row.step,
                    props.row.business_type
                  )
                "
              >
                <feather-icon icon="LogOutIcon" class="mr-50" />
                <span>Lanjut</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="
                  props.row.status === 2 &&
                  props.row.has_soft_qr === 0 &&
                  props.row.has_starter_pack === 0
                "
                @click="uploadSoftQR(props.row.id)"
              >
                <feather-icon icon="CameraIcon" class="mr-50" />
                <span>Bukti Soft QR</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="props.row.status === 2 && props.row.has_acquisition === 0"
                @click="setAcquisition(props.row.id)"
              >
                <feather-icon icon="EyeIcon" class="mr-50" />
                <span>Done Trx 10k</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="
                  props.row.status === 2 &&
                  props.row.has_soft_qr !== 0 &&
                  props.row.has_starter_pack === 0
                "
                @click="uploadStarterPack(props.row.id)"
              >
                <feather-icon icon="CameraIcon" class="mr-50" />
                <span>Bukti Starter Pack</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>

          <!-- Column: Common -->
          <span v-else class="d-block mt-50">
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing {{ meta.from }} to {{ meta.to }}
              </span>
              <span class="text-nowrap ml-50">
                of {{ meta.total }} entries
              </span>
            </div>
            <div>
              <b-pagination
                :value="meta.current_page"
                :total-rows="meta.total"
                :per-page="meta.per_page"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="
                  (value) =>
                    getDatas(
                      value,
                      this.subFilter ? this.subFilter : this.filter,
                      this.search,
                      this.filterDate
                    )
                "
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
    <div @click="fabAction">
      <vue-fab mainBtnColor="#3867d6" size="big" :scrollAutoHide="false">
      </vue-fab>
    </div>
  </div>
</template>

<script>
import { $themeConfig } from "@themeConfig";
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BModal,
  VBModal,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import downloadexcel from "vue-json-excel";
import moment from "moment";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axios from 'axios';
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    VBModal,
    downloadexcel,
    moment,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  setup() {
    const { baseUrl } = $themeConfig.app;
    return {
      baseUrl,
    };
  },
  data() {
    return {
      dir: false,
      columns: [
        { field: "user_name", label: "Agen", sortable: true, filterable: true },
        { field: "name", label: "Merchant", sortable: true, filterable: true },
        {
          field: "profiles.name",
          label: "PIC",
          sortable: true,
          filterable: true,
        },
        {
          field: "profiles.phone",
          label: "No Hp",
          sortable: false,
          filterable: true,
          width: "200px",
        },
        {
          field: "created_at",
          label: "Tanggal Submit",
          thClass: "text-center px-0",
          tdClass: "text-center",
          sortable: true,
          width: "175px",
        },
        {
          field: "status",
          label: "Status",
          thClass: "text-center px-0",
          tdClass: "text-center",
          sortable: true,
          width: "150px",
        },
        {
          field: "action",
          label: "Action",
          sortable: false,
          filterable: false,
          width: "100px",
          thClass: "text-center px-0",
          tdClass: "text-center",
        },
      ],
      rows: [],
      search: "",
      awaitingSearch: false,
      option: [],
      optionSub: [],
      filter: "ALL",
      subFilter: "ALL",
      showSubFilter: false,
      meta: {
        current_page: 1,
        last_page: 0,
        from: 0,
        to: 0,
        per_page: 10,
        total: 0,
      },
      dataExport: [],
      excel: {
        Agen: "user_name",
        Merchant: "name",
        PIC: "profiles.name",
        "No Hp": "profiles.phone",
        "Tanggal Submit": "created_at",
        Status: {
          field: "status", // nested attribute supported
          callback: (value) => {
            return this.statusName(value);
          },
        },
      },
      terms: this.baseUrl + "merchant/terms/form_terms_mechant_qr_danamon.pdf",
      filterDate: null,
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        0: "light-secondary",
        1: "light-primary",
        2: "light-success",
        3: "light-warning",
      };

      return (status) => statusColor[status];
    },
    statusName() {
      const statusName = {
        0: "Uncompleted",
        1: "Waiting Confirmation",
        2: "Approved",
        3: "Rejected",
      };

      return (status) => statusName[status];
    },
  },
  watch: {
    search: function (val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          if (this.showSubFilter) {
            this.getDatas(1, this.subFilter, this.search, this.filterDate);
          } else {
            this.getDatas(1, this.filter, this.search, this.filterDate);
          }
          this.awaitingSearch = false;
        }, 1000);
      }
      this.awaitingSearch = true;
    },
  },
  created() {
    this.getDatas(1, "", this.search, this.filterDate);
    this.getFilterList();
  },
  methods: {
    getDatas($page, $filter, $search, $date) {
      this.rows = [];
      if ($date != undefined) {
        var $rDateArr = $date.split(" to ");
      }
      this.$http
        .get(
          "/merchants?page=" +
            $page +
            ($filter ? "&filter=" + $filter : "") +
            ($search ? "&search=" + $search : "") +
            ($date
              ? "&start_at=" + $rDateArr[0] + "&end_at=" + $rDateArr[1]
              : "")
        )
        .then((res) => {
          this.rows = res.data.data;
          var meta = res.data.meta;
          if (meta.from != null) {
            this.meta.from = meta.from;
          }
          if (meta.to != null) {
            this.meta.to = meta.to;
          }
          this.meta.current_page = meta.current_page;
          this.meta.last_page = meta.last_page;
          this.meta.per_page = meta.per_page;
          this.meta.total = meta.total;
        });
    },
    getFilterList() {
      this.$http
        .get("/user/downline")
        .then((res) => {
          let $data = res.data.data;

          this.option.push({
            value: "ALL",
            text: "Semua",
          });

          this.option.push({
            value: "SELF",
            text: "Saya",
          });

          for (let i = 0; i < $data.length; i++) {
            this.option.push({
              value: $data[i].id,
              text: $data[i].name,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSubFilterList($id) {
      this.optionSub = [];
      this.$http
        .get("/user/downline?id=" + $id)
        .then((res) => {
          let $data = res.data.data;

          this.optionSub.push({
            value: $id,
            text: "Semua",
          });

          this.optionSub.push({
            value: $id + "&only=true",
            text: "Only",
          });

          for (let i = 0; i < $data.length; i++) {
            this.optionSub.push({
              value: $data[i].id + "&only=true",
              text: $data[i].name,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fabAction: function () {
      this.$router.push("/merchant/add");
    },
    showImage($filename) {
      this.media = this.baseUrl + "image/merchants/" + $filename;
    },
    onFilterChange() {
      this.search = null;
      this.getDatas(1, this.filter, this.search, this.filterDate);

      if (this.filter.toString() == "ALL" || this.filter.toString() == "SELF") {
        this.showSubFilter = false;
      } else {
        this.showSubFilter = true;
        this.getSubFilterList(this.filter);
      }
    },
    onSubFilterChange() {
      this.search = null;
      this.getDatas(1, this.subFilter, this.search, this.filterDate);
    },
    async fetchData() {
      const response = this.getDatas(
        1,
        this.filter.value,
        this.search,
        this.filterDate
      );
      return response.data.data;
    },
    startDownload() {
      alert("show loading");
    },
    finishDownload() {
      alert("hide loading");
    },
    showDetail($id) {
      this.$store.state.app.merchantId = $id;
      localStorage.setItem("merchantId", $id);
      this.$router.push("/merchant/detail");
    },
    onContinue($id, $step, $type) {
      this.$store.state.app.merchantId = $id;
      this.$store.state.app.businessType = $type;
      localStorage.setItem("merchantId", $id);
      localStorage.setItem("businessType", $type);

      switch ($step) {
        case 1:
          this.$router.push("/merchant/add/profile");
          break;
        case 2:
          this.$router.push("/merchant/add/bank");
          break;
        case 3:
          this.$router.push("/merchant/add/ktp");
          break;
        case 4:
          this.$router.push("/merchant/add/location");
          break;
        case 5:
          this.$router.push("/merchant/add/terms");
          break;
        case 6:
          if ($type == 2) {
            this.$router.push("/merchant/add/npwp");
          }
          break;
        default:
          break;
      }
    },
    uploadSoftQR($id) {
      this.$store.state.app.merchantId = $id;
      localStorage.setItem("merchantId", $id);
      this.$router.push("/merchant/add/soft-qr");
    },
    uploadStarterPack($id) {
      this.$store.state.app.merchantId = $id;
      localStorage.setItem("merchantId", $id);
      this.$router.push("/merchant/add/starter-pack");
    },
    format_date(value) {
      if (value) {
        return moment(value, "YYYY-MM-DD hh:mm:ss").format("DD-MM-YYYY");
      }
    },
    downloadForm() {
      window.open(this.terms, "_blank");
    },
    setAcquisition($id) {
      this.loading = true;
      this.$http
        .post("/merchants/" + $id + "/acquisition")
        .then((res) => {
          this.loading = false;
          this.$bvToast.toast("Berhasil", {
            title: "Berhasil",
            variant: "success",
            solid: true,
            autoHideDelay: 1000,
          });
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$bvToast.toast(err.response.data.message, {
            title: "Error",
            variant: "danger",
            solid: true,
            autoHideDelay: 1000,
          });
        });
    },
    onDateChange() {
      if (this.filterDate != undefined) {
        if (this.showSubFilter) {
          this.getDatas(1, this.subFilter, this.search, this.filterDate);
        } else {
          this.getDatas(1, this.filter, this.search, this.filterDate);
        }
      }
    },
    exportExcell() {
      var $filter = this.filter;
      var $search = this.search;
      var $date = this.date;

      if ($date != undefined) {
        var $rDateArr = $date.split(" to ");
      }

      axios({
      url: $themeConfig.app.apiUrl + "merchant/export?e=true" +
            ($filter ? "&filter=" + $filter : "") +
            ($search ? "&search=" + $search : "") +
            ($date
              ? "&start_at=" + $rDateArr[0] + "&end_at=" + $rDateArr[1]
              : ""),
      // timeout: 1000,
      responseType: 'blob',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
        'Content-Type': 'application/json',
        'Authorization' : 'Bearer ' + localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
      },
    }).then((res) => {
      const current = new Date();
        const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
        var fileURL = window.URL.createObjectURL(new Blob([res.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", date + "_report_merchant.xlsx");
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
